import React, { useState } from 'react';
import AppContainer from '../Components/AppContainer';
import { Platform, StyleSheet } from 'react-native';
import { Box, Text, Image, Heading, FormControl, Input, Button, Stack, InputGroup, Select } from 'native-base';
import Trans from '../Components/Trans';
import FormAction from '../../Actions/FormAction';
import GeneralAction from '../../Actions/GeneralAction';
import main from '../../Assets/Styles/main.json';
import APIAction from '../../Actions/APIAction';
import RequestCacheAction from '../../Actions/RequestCacheAction';

const mainStyle = StyleSheet.create(main);


const InvitationScreen = ({ navigation }) => {

    const
        [email, setEmail] = useState(null),
        [emailValid, setEmailValid] = useState(true),
        [phone, setPhone] = useState(null),
        [phoneValid, setPhoneValid] = useState(true),
        [countryCode, setCountryCode] = useState('+32');

    const onSubmit = async () => {
        let valid = true;

        setEmailValid(true);
        if (!FormAction.validateEmail(email)) {
            GeneralAction.toast('error', <Trans>Invalid email</Trans>);
            await GeneralAction.sleep(10);
            setEmailValid(false);
            valid = false;
        }

        setPhoneValid(true);
        if (!FormAction.validatePhone(countryCode + phone)) {
            GeneralAction.toast('error', <Trans>Invalid phone</Trans>);
            await GeneralAction.sleep(10);
            setPhoneValid(false);
            valid = false;
        }

        //if all values valid, invite employee
        if (valid) {
            //send invite
            let result = await APIAction.request({
                method: 'POST', url: '/api/employees/invite', catchFail: false, body: {
                    email: email,
                    phone: (countryCode + phone)
                }
            });
            await RequestCacheAction.clear({ url: 'employees' });

            //if error
            if (result === false) {
                GeneralAction.toast('error', <Trans>Invitation could not be send</Trans>);
            } else if ('status' in result && result['status'] === 'OK') {
                GeneralAction.toast('success', <Trans>Invitation send</Trans>);
                navigation.replace('OrganizationOverview');
            } else if ('status' in result && result['message'] === 'already invited') {
                GeneralAction.toast('info', <Trans>Invitation already send</Trans>);
            } else {
                GeneralAction.toast('error', <Trans>User could not be invited</Trans>);
            }
            await GeneralAction.sleep(10);
        }

    };

    return (
        <AppContainer>
            <Box style={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Image
                    style={{
                        resizeMode: "contain",
                        width: 250,
                        height: 156
                    }}
                    source={require('../../Assets/Images/invitation.png')}
                />
            </Box>

            <Heading style={{ color: '#000', fontWeight: '500', lineHeight: 28, textAlign: 'center', fontSize: 24 }}><Trans>Invite a person to</Trans></Heading>
            <Heading style={{ color: '#000', fontWeight: '700', lineHeight: 28, textAlign: 'center', fontSize: 24 }}><Trans>work for you</Trans></Heading>

            <Stack
                mt={4}
                space={4}
                alignItems={{
                    base: 'stretch',
                    lg: 'flex-end'
                }}
                direction={{
                    base: 'column',
                    lg: 'row'
                }}
            >
                <Box flex={1}>
                    <FormControl isRequired isInvalid={!emailValid}>
                        <FormControl.Label><Text><Trans>Email</Trans></Text></FormControl.Label>
                        <Input
                            type={'text'}
                            autoCapitalize={'none'}
                            autoCorrect={false}
                            autoComplete={'email'}
                            onChangeText={(val) => setEmail(val)}
                        />
                    </FormControl>
                </Box>
                <Box flex={1}>
                    <Box>
                        <FormControl.Label isRequired><Text><Trans>Mobile number</Trans></Text></FormControl.Label>
                        <InputGroup  >
                            <Select
                                isInvalid={!phoneValid}
                                w={'100'}
                                placeholder={Platform.OS === 'web' ? <Trans>Country code</Trans> : null}
                                value={countryCode}
                                selectedValue={countryCode}
                                onValueChange={(val) => setCountryCode(val)}
                            >
                                <Select.Item label={'+32'} value={'+32'} />
                                <Select.Item label={'+31'} value={'+31'} />
                            </Select>

                            <Input
                                isInvalid={!phoneValid}
                                flexGrow={1}
                                flexShrink={1}
                                type={'text'}
                                autoCapitalize={'none'}
                                autoCorrect={false}
                                autoComplete={'tel'}
                                onChangeText={(val) => setPhone(val)}
                            />
                        </InputGroup>
                    </Box>
                </Box>
                <Box>
                    <Button
                        onPress={() => onSubmit()}
                    >
                        <Text style={mainStyle.blueButtonText}><Trans>Invite person</Trans></Text>
                    </Button>
                </Box>
            </Stack>
        </AppContainer>
    );
}

export default InvitationScreen;