import React, { useCallback, useEffect, useState, useRef } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Pressable, Heading, Text, VStack, Skeleton } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import main from '../../../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../../../Assets/Fontello/line-awesome-config.json';
import CalendarHeader from '../../Components/CalendarHeader';
import CalendarRow from '../../Components/CalendarRow';
import GeneralAction from '../../../../Actions/GeneralAction';
import APIAction from '../../../../Actions/APIAction';
import RequestCacheAction from '../../../../Actions/RequestCacheAction';
import UserAction from '../../../../Actions/UserAction';
import Trans from '../../../Components/Trans';
import { v4 as uuidv4 } from 'uuid';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const DayBox = (props) => {

    const
        firstLoad = useRef(true),
        [refresh, setRefresh] = useState(uuidv4()),
        [header, setHeader] = useState(null),
        [shifts, setShifts] = useState(null),
        [shiftElements, setShiftElements] = useState(null),
        [length, setLength] = useState(0),
        [minDate, setMinDate] = useState(null),
        [maxDate, setMaxDate] = useState(null),
        [weekday, setWeekday] = useState(''),
        [fullDate, setFullDate] = useState(''),
        [isToday, setIsToday] = useState(''),
        [ready, setReady] = useState(false)
        ;

    const collapseStyle = {
        height: 'auto',
        overflow: 'hidden'
    };

    //create title first
    const onFirstLoad = useCallback(() => {
        const init = async () => {

            /* SET DATE */
            let formatWeekday = await GeneralAction.formatLocal(props.date, { weekday: 'long' });
            setWeekday(formatWeekday);

            let formatFullDate = await GeneralAction.formatLocal(props.date, { day: 'numeric', month: 'long' });
            setFullDate(formatFullDate);

            let today = new Date();
            today.setHours(0, 0, 0, 0);
            setIsToday(JSON.stringify(today) == JSON.stringify(props.date));

        };
        init();
    }, [props]);

    //set header when open
    useEffect(() => {
        const init = async () => {

            let date = new Date(props.date);

            /* GET SHIFTS */
            let currentUser = await UserAction.getUser();
            let clientId = GeneralAction.iriToId(currentUser.client);
            await RequestCacheAction.clear({url: 'shifts'});

            /* QUEUE REQUESTS */
            let requestQueue = [];
            requestQueue.push(
                APIAction.request({
                    method: 'GET',
                    url: '/api/shifts',
                    params: {
                        onDate: date.toISOString(),
                        empty: true
                    },
                    cache: false
                })
            );

            requestQueue.push(
                APIAction.request({
                    method: 'GET', url: '/api/employee_shifts', params: {
                        clientId: clientId,
                        onDate: date.toISOString()
                    }, cache: false
                })
            );

            let requestQueueRes = await Promise.all(requestQueue);

            let apiMainShifts = requestQueueRes[0];
            apiMainShifts = apiMainShifts['hydra:member'];

            let apiShifts = requestQueueRes[1];
            apiShifts = apiShifts['hydra:member'];

            /* ADD SHIFTS TOGETHER */
            for (let mainShift of apiMainShifts) {
                apiShifts.push({ shift: mainShift });
            }


            /* SORT BY DATE */
            apiShifts.sort((a, b) => {
                if (a.shift.startOn < b.shift.startOn) return -1;
                if (a.shift.startOn > b.shift.startOn) return 1;
                return 0;
            });

            /* SET HEADER IN STATE */
            setHeader(
                <CalendarHeader
                    key={uuidv4()}
                    shifts={apiShifts}
                    date={date}
                    onLength={(hLenght) => {
                        setLength(hLenght);
                    }}
                    onMinDate={(hMin) => {
                        setMinDate(new Date(hMin));
                    }}
                    onMaxDate={(hMax) => {
                        setMaxDate(new Date(hMax));
                    }}
                />
            );

            /* SET SHIFTS IN STATE */
            setShifts(apiShifts);
        }
        if (shifts === null) init();
    }, [props, shifts]);

    //then load other items
    useEffect(() => {
        const init = async () => {

            /* SORT BY DATE */
            let apiShifts = shifts
            apiShifts.sort((a, b) => {
                if (a.shift.startOn < b.shift.startOn) return -1;
                if (a.shift.startOn > b.shift.startOn) return 1;
                return 0;
            });

            /* SPLIT BY EMPLOYEE */
            let employees = {};
            let employeeShifts = {};

            for (let shift of apiShifts) {
                let employeeId = 'none_' + uuidv4();
                if (shift.employee) {
                    employeeId = shift.employee['@id'];
                }
                if (!(employeeId in employeeShifts)) {
                    employees[employeeId] = shift.employee;
                    employeeShifts[employeeId] = [];
                }

                employeeShifts[employeeId].push(shift);
            }

            /* CREATE SHIFT ITEMS */
            let newShiftElements = [];
            let departments = [];

            for (let [employeeId, shifts] of Object.entries(employeeShifts)) {
                let id = uuidv4();


                let sortedShifts = [];
                for(let shift of shifts){
                    if(shift.shift.department){
                        let alreadyExists = false;
                        departments.forEach(department => {
                            if(department['@id'] == shift.shift.department['@id']){
                                alreadyExists = true;
                            }
                        });

                        if(!alreadyExists){
                            departments.push(shift.shift.department);
                        }
                    }
                }
            }

            let nrAddedShifts = 0;
            let totalShifts = 0;

            //Add shifts if no departments
            if( departments.length <= 1){
                for (let [employeeId, shifts] of Object.entries(employeeShifts)) {
                    let id = uuidv4();

                    newShiftElements.push(
                        <CalendarRow
                            key={id}
                            shifts={shifts}
                            startOn={minDate}
                            endOn={maxDate}
                            date={props.date}
                            employeeId={employeeId}
                            reload={() => {
                                setMinDate(null);
                                setMaxDate(null);
                                setShifts(null);
                                setShiftElements(null);
                                firstLoad.current = true;
                                setRefresh(uuidv4());
                            }}
                        />
                    );
                }
            }
            //Add shifts with departments
            else {
                departments.forEach(department => {

                    //Department title
                    newShiftElements.push(
                        <Heading style={mainStyle.pageTitle}>{department.name}</Heading>
                    );

                    totalShifts = 0;
                    //Add shifts under department
                    for(let [employeeId, shifts] of Object.entries(employeeShifts)) {
                        let id = uuidv4();

                        //another loop is needed because they are nested per employee
                        shifts.forEach( shift => {
                            if(shift.shift.department != null && shift.shift.department['@id'] == department['@id']){
                                newShiftElements.push(
                                    <CalendarRow
                                        key={id}
                                        shifts={[shift]}
                                        startOn={minDate}
                                        endOn={maxDate}
                                        date={props.date}
                                        employeeId={employeeId}
                                        reload={() => {
                                            setMinDate(null);
                                            setMaxDate(null);
                                            setShifts(null);
                                            setShiftElements(null);
                                            firstLoad.current = true;
                                            setRefresh(uuidv4());
                                        }}
                                    />
                                );

                                nrAddedShifts++;
                            }
                            totalShifts++;
                        })
                    }
                });

                //Print shifts without department
                if(nrAddedShifts < totalShifts){
                    newShiftElements.push(
                        <Heading style={mainStyle.pageTitle}><Trans>Geen department</Trans></Heading>
                    );

                    for(let [employeeId, shifts] of Object.entries(employeeShifts)) {
                        let id = uuidv4();

                        //another loop is needed because they are nested per employee
                        shifts.forEach( shift => {
                            if(shift.shift.department == null){
                                newShiftElements.push(
                                    <CalendarRow
                                        key={id}
                                        shifts={[shift]}
                                        startOn={minDate}
                                        endOn={maxDate}
                                        date={props.date}
                                        employeeId={employeeId}
                                        reload={() => {
                                            setMinDate(null);
                                            setMaxDate(null);
                                            setShifts(null);
                                            setShiftElements(null);
                                            firstLoad.current = true;
                                            setRefresh(uuidv4());
                                        }}
                                    />
                                );
                            }
                        });
                    }
                }
            }

            //add last row
            newShiftElements.push(
                    <CalendarRow
                        key={uuidv4()}
                        shifts={[]}
                        startOn={minDate}
                        endOn={maxDate}
                        date={props.date}
                        isLastRow={true}
                        reload={() => {
                            setMinDate(null);
                            setMaxDate(null);
                            setShifts(null);
                            setShiftElements(null);
                            firstLoad.current = true;
                            setRefresh(uuidv4());
                        }}
                    />
            );

            /* SET IN STATE */
            setShiftElements(newShiftElements);
            setReady(true);
        }

        if (minDate && maxDate && shifts && (shiftElements === null)) init();
    }, [minDate, maxDate, shifts, shiftElements, props])

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            onFirstLoad();
        }
    }, [onFirstLoad, props, refresh]);

    return (
        <>
            <Box style={isToday ? mainStyle.currentDayBox : ''}>
                <Pressable style={mainStyle.collapseButton}>
                    <Box style={mainStyle.collapseButtonContent}>
                        <Heading style={[mainStyle.mediumTitle, mainStyle.collapseButtonText, isToday ? mainStyle.blue : '']}> {weekday} <Text style={mainStyle.smallText}>- {fullDate}</Text> </Heading>
                    </Box>
                </Pressable>

                {/* collapsible */}
                <Box style={collapseStyle}>
                    <Box style={mainStyle.whiteBoxCollapseContent}>
                        {/* <ScrollView horizontal contentContainerStyle={{ minWidth: '100%', width: length * 60 }}> */}
                        <VStack w={'100%'} space={2} style={{ flexWrap: 'wrap' }}>
                            {header}
                            {!ready ?
                                <Skeleton.Text /> :
                                // <>
                                shiftElements
                                // </>
                            }
                        </VStack>
                        {/* </ScrollView> */}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default DayBox;
