import React, {useCallback, useEffect, useState, useRef} from 'react';
import { StyleSheet, Image } from 'react-native';
import { Pressable, Box, Text, HStack, Button, Popover, Stack, AlertDialog, IconButton } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import UserImage from '../../Components/UserImage';
import CalendarEditPane from './CalendarEditPane';
import Trans from '../../Components/Trans';
import GeneralAction from '../../../Actions/GeneralAction';
import APIAction from '../../../Actions/APIAction';
import RequestCacheAction from '../../../Actions/RequestCacheAction';

import main from '../../../Assets/Styles/main.json';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import UserAction from "../../../Actions/UserAction";

const mainStyle = StyleSheet.create(main);
const Icon = createIconSetFromFontello(lineAwesomeConfig);

const CalendarShiftBlock = (props) => {
    const
        firstLoad = useRef(true),
        [deleteModal, setDeleteModal] = useState(false),
        [showEdit, setShowEdit] = useState(false),
        [alias, setAlias] = useState(false)
        ;

    const onFirstLoad = useCallback(() => {
        const fetchData = async () => {
            const job = props.shift;
            let user = await UserAction.getUser();
            let clientId = GeneralAction.iriToId(user.client);
    
            if(job.shift && job.shift.position){
                setAlias(await GeneralAction.getAlias(job.shift.position, clientId));
            }
        };
        fetchData();
    }, [props.shift]);
    
    useEffect(() => {
        if (firstLoad.current) {
            onFirstLoad();
            firstLoad.current = false;
        }
    }, [onFirstLoad]);


    let contractStatusColor = "#B4B4B4";
    let contractStatusText = <Trans>Geen contract</Trans>;
    if (props.shift.contract && props.shift.contract.complete) {
        contractStatusColor = "#3BB143";
        contractStatusText = <Trans>Afgewerkt contract</Trans>
    }
    else if (props.shift.contract && !props.shift.contract.complete) {
        contractStatusColor = "#FFA500";
        contractStatusText = <Trans>Contract niet afgewerkt</Trans>
    }
    if (props.shift.easyPayErrors && props.shift.easyPayErrors.length > 0) {
        props.shift.easyPayErrors.forEach(function( error ){
            if(error.active){
                contractStatusColor = "#FF6363";
                contractStatusText = <Trans>Er is een fout opgetreden bij het aanmaken van het contract. Gelieve contact op te nemen met Silver Tie.</Trans>;
            }
        })
    }

    let dimonaStatusName = "";
    if (props.shift.contract && props.shift.contract.dimonaStatus) {
        let fullName = props.shift.contract.dimonaStatus.name["nl"];
        if (/-[A-Z]{2}$/.test(fullName)) {
            dimonaStatusName = fullName.slice(0, -3).trim();
        } else {
            dimonaStatusName = fullName;
        }

    } else {
        dimonaStatusName = "Geen contract";
    }

    const remove = async () => {

        let response = await APIAction.request({
            method: 'PATCH',
            url: `/api/shifts/${props.shift.shift.id}`,
            body: {
                deleted: true
            }
        });
        await RequestCacheAction.clear({ url: 'projects' });
        setDeleteModal(false);

        if (typeof props.reload === 'function') {
            props.reload();
        }
    }

    return (
        <>
            <Pressable style={{ width: props.width, paddingVertical: 3 }} onPress={() => {
                if (typeof props.onPress === 'function') props.onPress();
                setShowEdit(true);
            }}>
                <Box flexDirection={'column'} alignItems={'center'} style={[mainStyle.previewPlaceButtonPill, { backgroundColor: '#E9F5FB' }]}>
                    {props.shift.employee ?
                        <Stack
                            direction={{
                                base: 'column',
                                sm: 'row'
                            }}
                            justifyContent={{
                                base: 'flex-start',
                                sm: 'space-between'
                            }}
                            alignItems={{
                                base: 'flex-start',
                                sm: 'center'
                            }}
                            space={2}
                            width="100%"
                        >
                            <HStack alignItems={'center'} space={2} flexShrink={1} maxW={'100%'}>
                                <UserImage user={props.shift.employee.user} style={{ height: 30, width: 30 }} />
                                <Text style={{ fontSize: 11 }} numberOfLines={1}>{props.shift.employee.user.firstName} {props.shift.employee.user.lastName} ({props.shift.employee.contractType ? props.shift.employee.contractType.name["nl"] : ''})</Text>
                                <Text flexShrink={1} opacity={0.6} style={{ fontSize: 11 }} numberOfLines={1}>{alias ? alias : props.shift.shift.position.name}</Text>
                            </HStack>
                            <HStack alignItems={"center"} space={2}>
                                <Text style={[mainStyle.timeLabel, { borderColor: '#02AAFF', borderWidth: 1 }]}>{GeneralAction.formatTime(props.shift.shift.startOn)} - {GeneralAction.formatTime(props.shift.shift.endOn)}</Text>
                                {/* Dimona icon */}
                                <Popover trigger={triggerProps => {
                                    return <Button {...triggerProps} p={0} backgroundColor={'transparent'}>
                                        <Box style={{ backgroundColor: props.shift.contract && props.shift.contract.dimonaStatus ? props.shift.contract.dimonaStatus.color : "#B4B4B4", justifyContent: "center", alignItems: "center", borderRadius: 30, width: 28, height: 28 }}>
                                            <Image resizeMode="contain" style={[{ width: 18, height: 18 }]} source={require('./../../../Assets/Images/dimonaLightBlue.png')} />
                                        </Box>
                                    </Button>
                                }}
                                >
                                    <Popover.Content>
                                        <Popover.Body>
                                            <Text>{dimonaStatusName}1</Text>
                                        </Popover.Body>
                                    </Popover.Content>
                                </Popover>
                                {/* Contract icon */}
                                <Popover trigger={triggerProps => {
                                    return <Button {...triggerProps} p={0} backgroundColor={'transparent'} borderRadius={'full'}>
                                        <Box backgroundColor={contractStatusColor} borderRadius={'full'}>
                                            <Icon name={'file-contract'} size={18} color={"#E9F5FB"} style={{ padding: 5 }} />
                                        </Box>

                                    </Button>
                                }}
                                >
                                    <Popover.Content>
                                        <Popover.Body>
                                            <Text>{contractStatusText}</Text>
                                        </Popover.Body>
                                    </Popover.Content>
                                </Popover>
                                {new Date(props.shift.shift.startOn) > new Date() &&
                                    <IconButton variant={'subtle'} borderRadius={'full'} colorScheme={'danger'} icon={<Icon color={"#e11d48"} name={'trash'} size={16} />} onPress={() => {
                                        setDeleteModal(true);
                                    }} />

                                }
                            </HStack>
                        </Stack> :
                        <Stack
                            direction={{
                                base: 'column',
                                sm: 'row'
                            }}
                            justifyContent={{
                                base: 'flex-start',
                                sm: 'space-between'
                            }}
                            alignItems={{
                                base: 'flex-start',
                                sm: 'center'
                            }}
                            space={2}
                            width={'100%'}
                        >
                            <HStack alignItems={'center'} space={2} flexShrink={1}>
                                <Box
                                    width={'30px'}
                                    height={'30px'}
                                    backgroundColor={'#fff'}
                                    borderRadius={'full'}
                                    borderWidth={1}
                                    borderColor={'#ddd'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Text fontSize={18}>?</Text>
                                </Box>
                                <Box backgroundColor={'#fff'}>
                                    <Button
                                        leftIcon={<Icon style={{ color: '#02AAFF' }} size={16} name={'plus'} />}
                                        borderRadius={'md'}
                                        variant={'outline'}
                                        colorScheme={'primary'}
                                        size={'sm'}
                                        onPress={() => {
                                            if (typeof props.onPress === 'function') props.onPress();
                                            setShowEdit(true);
                                        }}
                                    >
                                        <Text fontSize={12} color={'primary.600'}><Trans>Select employee</Trans></Text>
                                    </Button>
                                </Box>
                                <Text opacity={0.6} style={{ fontSize: 11 }} numberOfLines={1}>{alias ? alias : props.shift.shift.position.name}</Text>

                            </HStack>
                            <HStack alignItems={"center"} space={2}>
                                <Text style={[mainStyle.timeLabel, { borderColor: '#02AAFF', borderWidth: 1 }]}>{GeneralAction.formatTime(props.shift.shift.startOn)} - {GeneralAction.formatTime(props.shift.shift.endOn)}</Text>
                                {/* Dimona icon */}
                                <Popover trigger={triggerProps => {
                                    return <Button {...triggerProps} p={0} backgroundColor={'transparent'}>
                                        <Box style={{ backgroundColor: props.shift.contract && props.shift.contract.dimonaStatus ? props.shift.contract.dimonaStatus.color : "#B4B4B4", justifyContent: "center", alignItems: "center", borderRadius: 30, width: 28, height: 28 }}>
                                            <Image resizeMode="contain" style={[{ width: 18, height: 18 }]} source={require('./../../../Assets/Images/dimonaLightBlue.png')} />
                                        </Box>
                                    </Button>
                                }}
                                >
                                    <Popover.Content>
                                        <Popover.Body>
                                            <Text>{dimonaStatusName}</Text>
                                        </Popover.Body>
                                    </Popover.Content>
                                </Popover>
                                {/* Contract icon */}
                                <Popover trigger={triggerProps => {
                                    return <Button {...triggerProps} p={0} backgroundColor={'transparent'} borderRadius={'full'}>
                                        <Box backgroundColor={contractStatusColor} borderRadius={'full'}>
                                            <Icon name={'file-contract'} size={18} color={"#E9F5FB"} style={{ padding: 5 }} />
                                        </Box>

                                    </Button>
                                }}
                                >
                                    <Popover.Content>
                                        <Popover.Body>
                                            <Text>{contractStatusText}</Text>
                                        </Popover.Body>
                                    </Popover.Content>
                                </Popover>
                                {new Date(props.shift.shift.startOn) > new Date() &&
                                    <IconButton variant={'subtle'} borderRadius={'full'} colorScheme={'danger'} icon={<Icon color={"#e11d48"} name={'trash'} size={16} />} onPress={() => {
                                        setDeleteModal(true);
                                    }} />
                                }
                            </HStack>
                        </Stack>
                    }
                    {/* <Text mx={2} opacity={0.6} style={{ fontSize: 11, textAlign: 'center' }} numberOfLines={1}>{props.shift.shift.position.alias ? props.shift.shift.position.alias : props.shift.shift.position.name}</Text> */}
                </Box>
            </Pressable>
            <AlertDialog isOpen={deleteModal} onClose={() => setDeleteModal(false)}>
                <AlertDialog.Content>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Header><Text><Trans>Delete</Trans></Text></AlertDialog.Header>
                    <AlertDialog.Body>
                        <Text><Trans>Delete shift:</Trans> <Text bold>{GeneralAction.formatTime(props.shift.shift.startOn)} - {GeneralAction.formatTime(props.shift.shift.endOn)} {alias ? alias : props.shift.shift.position.name}</Text>?</Text>
                    </AlertDialog.Body>
                    <AlertDialog.Footer>
                        <Button.Group space={2}>
                            <Button variant={'ghost'} onPress={() => setDeleteModal(false)}>
                                <Text><Trans>Cancel</Trans></Text>
                            </Button>
                            <Button
                                colorScheme={'danger'}
                                onPress={() => {
                                    remove();
                                }}
                            >
                                <Text color={'#fff'}><Trans>Delete</Trans></Text>
                            </Button>
                        </Button.Group>
                    </AlertDialog.Footer>
                </AlertDialog.Content>
            </AlertDialog>
            <CalendarEditPane
                date={props.date}
                startOn={props.shift.shift.startOn}
                endOn={props.shift.shift.endOn}
                shift={props.shift}
                open={showEdit}
                onClose={() => setShowEdit(false)}
                reload={() => {
                    if (typeof props.reload === 'function') props.reload();
                }}
            />
        </>
    );
};

export default CalendarShiftBlock;
