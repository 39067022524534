import React from 'react';
import { Toast as NBToast } from 'native-base';
import Toast from '../Views/Components/Toast';
import TranslationAction from './TranslationAction';
import { getAlpha2Codes } from 'i18n-iso-countries';
import APIAction from "./APIAction";

const regionMapping = {
    'nl': 'nl-BE',
    'en': 'en-GB',
    'fr': 'fr-FR',
    'de': 'de-DE'
};

const GeneralAction = {
    // Format price in the following format: € 10.99
    formatPrice: (price) => {
        return '€ ' + parseFloat(price).toFixed(2);
    },

    // Get part of a string
    substring: (text, start, end) => {
        return text.substring(start, end);
    },

    // Trim a text
    trim: (text) => {
        return text.trim();
    },

    //show toast
    toast: (status, text) => {

        NBToast.show({
            placement: 'top',
            render: () => {
                return (
                    <Toast status={status}>
                        {text}
                    </Toast>
                );
            },
        });
    },

    //format date from ISO string
    formatDate: (ISOString, isoFormat = false) => {

        //create date object
        let date = new Date(ISOString);

        //define format
        let dateFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });

        //apply
        let [{ value: month }, , { value: day }, , { value: year }] = dateFormat.formatToParts(date);

        //return string
        if (isoFormat) {
            return `${year}-${month}-${day}`;
        }
        return `${day}/${month}/${year}`;
    },

    //format date & time from ISO string
    formatDateTime: (ISOString) => {

        //create date object
        let date = new Date(ISOString);

        //define format
        let dateFormat = new Intl.DateTimeFormat('nl-BE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });

        //apply
        let [{ value: day }, , { value: month }, , { value: year }, , { value: hour }, , { value: minute }] = dateFormat.formatToParts(date);

        //return string
        return `${day}/${month}/${year} ${hour}:${minute}`;
    },

    //format date & time from ISO string
    formatDateFull: (ISOString, locale = 'en', weekday = 'long', day = 'numeric', month = 'long', year = 'numeric') => {

        //create date object
        let date = new Date(ISOString);

        // Define format
        let options = { day: day };

        if (weekday !== false) {
            options.weekday = weekday;
        }

        if (month !== false) {
            options.month = month;
        }

        if (year !== false) {
            options.year = year;
        }

        let dateFormat = new Intl.DateTimeFormat(locale, options);

        //return
        return dateFormat.format(date);
    },

    //format time
    formatTime: (ISOString) => {

        //create date object
        let date = new Date(ISOString);

        //define format
        let dateFormat = new Intl.DateTimeFormat('nl-BE', { hour: '2-digit', minute: '2-digit' });

        //apply
        let [{ value: hour }, , { value: minute }] = dateFormat.formatToParts(date);

        //return string
        return `${hour}:${minute}`;
    },

    //format minutes to string
    formatMinutes: async (minutes) => {
        let language = await TranslationAction.getSelectedLanguage();
        let languageCode = language.code;

        if (!Object.keys(regionMapping).includes(languageCode)) {
            languageCode = 'en';
        }

        let locale = regionMapping[languageCode];

        let partMin = minutes % 60;
        minutes = minutes - partMin;
        let hours = minutes / 60;
        let partHour = hours % 24;
        let partDay = (hours - partHour) / 24;

        let formattedDate = '';
        if (partDay > 0) {
            formattedDate += new Intl.NumberFormat(locale, {
                style: 'unit',
                unit: 'day',
                unitDisplay: 'narrow',
            }).format(partDay);
        }

        if (partHour > 0) {
            formattedDate += partDay !== 0 ? ' ' : '';
            formattedDate += new Intl.NumberFormat(locale, {
                style: 'unit',
                unit: 'hour',
                unitDisplay: 'narrow',
            }).format(partHour);
        }

        if (partMin > 0 || (partDay === 0 && partHour === 0)) {
            formattedDate += (partDay !== 0) || (partHour !== 0) ? ' ' : '';
            formattedDate += new Intl.NumberFormat(locale, {
                style: 'unit',
                unit: 'minute',
                unitDisplay: 'narrow',
            }).format(partMin);
        }

        return formattedDate;
    },

    getWeekNrFromDate: (date) => {
        date = new Date(date);
        const startOfYear = new Date(date.getFullYear(), 0, 1);
        let startOfWeek = new Date(
            // Standard sunday is 0 and monday 1, but our weeks start on monday, this line makes sure monday is 0
            // If getDay() returns 0 (which indicates Sunday), it is treated as the 6th day of the week by subtracting it from 6. 
            // If the getDay() returns any other day (Monday - Saturday), it is compensated by subtracting 1 so that Monday becomes 
            // the start of the week. For example, if it is Tuesday, getDay() will return 2. We subtract 1, and it becomes the 
            // 1st day of the week in our context (Monday being 0).
            startOfYear.setDate(startOfYear.getDate() - (startOfYear.getDay() === 0 ? 6 : startOfYear.getDay() - 1))
        ); 

        // The first week of the year only counts as the first week when more than 4 days fall in this week
        // Ex: sunday 1 january is not the first week, because only 1/7 days fall in the new year
        let dayNumber = ((date.getTime() - startOfYear.getTime()) / (1000 * 3600 * 24)) + 1;
        let weekNumber = Math.ceil(dayNumber / 7); 
        let fixFirstWeek = 0;
        if (new Date(date.getFullYear(), 0, 1).getDay() > 4 || new Date(date.getFullYear(), 0, 1).getDay() == 0) {
            fixFirstWeek = -1;
        }

        const diffInTime = date.getTime() - startOfWeek.getTime();
        const diffInWeeks = Math.floor(diffInTime / (1000 * 3600 * 24 * 7));

        return diffInWeeks + 1 + fixFirstWeek; 
    },

    getWeeksInMonth: (date) => {
        let startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        let endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        startOfMonth.setDate(startOfMonth.getDate() - (startOfMonth.getDay() === 0 ? 6 : startOfMonth.getDay() - 1));
        endOfMonth.setDate(endOfMonth.getDate() - (endOfMonth.getDay() === 0 ? 6 : endOfMonth.getDay() - 1));

        const diffInTime = endOfMonth.getTime() - startOfMonth.getTime();
        const diffInWeeks = Math.floor(diffInTime / (1000 * 3600 * 24 * 7));

        // If february has only 28 days, an extra week should be added
        let extraDayLeapYear = 0
        if(new Date(date.getFullYear(), date.getMonth(), 0).getDate() == 28){
            extraDayLeapYear = 1;
        }

        return diffInWeeks + 1 + extraDayLeapYear;
    },
    getWeekStartEnd: (year, week) => {
        let simple = new Date(year, 0, 1 + (week - 1) * 7); // First day of the year plus week's worth of days
        let dow = simple.getDay();
        let weekStart = simple;
        
        if (dow <= 4) {
            weekStart.setDate(simple.getDate() - simple.getDay() + 2);
        } else {
            weekStart.setDate(simple.getDate() + 8 - simple.getDay() + 1);
        }
        
        let weekEnd = new Date(weekStart);
        weekEnd.setDate(weekEnd.getDate() + 6);
      
        return {
            firstDay: weekStart.toISOString().substring(0, 10), // Format to 'yyyy-mm-dd'
            lastDay: weekEnd.toISOString().substring(0, 10),  // Format to 'yyyy-mm-dd'
        };
    },
    //format for local date
    formatLocal: async (date, options = {}) => {
        let language = await TranslationAction.getSelectedLanguage();
        let languageCode = language.code;

        if (!Object.keys(regionMapping).includes(languageCode)) {
            languageCode = 'en';
        }

        let locale = regionMapping[languageCode];
        return new Intl.DateTimeFormat(locale, options).format(date);
    },

    //timzone offset to timezone
    tzOffsetToTz: (tzOffset) => {
        let sign = tzOffset > 0 ? '-' : '+';
        let absOffset = Math.abs(tzOffset);
        let hours = Math.floor(absOffset / 60) + '';
        hours = hours.padStart(2, '0');
        let minutes = absOffset % 60 + '';
        minutes = minutes.padStart(2, '0');
        return sign + hours + ':' + minutes;
    },

    //changetimezone date
    changeTimezone: (value, tz) => {
        let strDate = new Date(value).toString();
        let indexDate = strDate.indexOf('GMT');
        strDate = strDate.substring(0, indexDate + 3);
        strDate += tz.replace(':', '');
        return new Date(strDate);
    },

    //is same date
    isSameDate: (date1, date2) => {
        let formatter = new Intl.DateTimeFormat('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: 'europe/brussels'
        });
        let date1Str = formatter.format(new Date(date1));
        let date2Str = formatter.format(new Date(date2));
        return date1Str === date2Str;
    },

    //sleep for given amount of milliseconds (default 1 second)
    sleep: async (milliseconds = 1000) => {
        return await new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, milliseconds);
        });
    },

    //get id from iri
    iriToId: (iri) => {
        let matches = iri.match(/([A-Z0-9]){26}$/g);
        if (matches.length > 0) return matches[0];
        else return null;
    },

    // Get all countries
    getCountries: async () => {

        // Get current language
        let language = await TranslationAction.getSelectedLanguage();

        // Get country codes
        let countryCodes = getAlpha2Codes();
        countryCodes = Object.keys(countryCodes);

        // Get region names
        let countries = {};
        let regionNames = new Intl.DisplayNames([language.code], { type: 'region' });
        for (let countryCode of countryCodes) {
            countries[countryCode] = regionNames.of(countryCode);
        }

        // Sort countries
        let sortableCountries = [];
        for (let [countryCode, countryName] of Object.entries(countries)) {
            sortableCountries.push(`${countryName}$_SEP_${countryCode}`);
        }
        sortableCountries.sort();

        let sortedCountries = {};
        for (let sortableCountry of sortableCountries) {
            let splitCountry = sortableCountry.split('$_SEP_');
            sortedCountries[splitCountry[1]] = `[${splitCountry[1]}] ${splitCountry[0]}`;
        }

        // Return
        return sortedCountries;
    },

    // Get language
    getLanguage: async (code) => {
        // Get current language
        let language = await TranslationAction.getSelectedLanguage();

        // Get language names
        let languageNames = new Intl.DisplayNames([language.code], { type: 'language' });
        return languageNames.of(code);
    },

    // Get country
    getCountry: async (code) => {
        // Get current language
        let language = await TranslationAction.getSelectedLanguage();

        // Get region names
        let regionNames = new Intl.DisplayNames([language.code], { type: 'region' });
        return regionNames.of(code);
    },

    // Get genders
    getGenders: async () => {

        let maleTrans = await TranslationAction.translate('Male');
        let femaleTrans = await TranslationAction.translate('Female');
        let otherTrans = await TranslationAction.translate('none/other');

        return {
            'M': maleTrans,
            'F': femaleTrans,
            'X': otherTrans
        };
    },

    // Get sizes
    getSizes: async () => {
        return ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'];
    },
    getAlias: async (position, client) => {
        // Get alias from api
        let selectedAlias = [];
        if(position && client){
            selectedAlias = await APIAction.request({
                method: 'GET',
                url: '/api/find/aliases',
                params: {
                    positionId: position.id,
                    clientId: client,
                },
                cache: true
            });
        }

        // Fallback if no alias
        let alias = position.name;

        // Get selected alias
        if (selectedAlias && 'alias' in selectedAlias) {
            alias = selectedAlias.alias
        }

        return alias;
    },
};

export default GeneralAction;