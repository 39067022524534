import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, FormControl, Switch, Heading, HStack, Stack, Text, Select } from 'native-base';
import AppContainer from '../Components/AppContainer';
import { useIsFocused } from '@react-navigation/native';
import APIAction from '../../Actions/APIAction';
import Trans from '../Components/Trans';
import Placeholder from '../Components/Placeholder';
import { v4 as uuidv4 } from 'uuid';
import UserAction from '../../Actions/UserAction';
import Details from './Details';
import GeneralAction from '../../Actions/GeneralAction';
import { StyleSheet } from 'react-native';
import main from '../../Assets/Styles/main.json';
import DateTimePicker from '../../Libs/DateTimePicker';
import { NumberInput } from "../../Libs/CustomInputs";
import TranslationAction from '../../Actions/TranslationAction';
import RowItem from '../Job/RowItem';

const mainStyle = StyleSheet.create(main);
const ApproveJob = (props) => {
    // Init
    let id = '';
    const isFocused = useIsFocused();

    let [jobPreviewElement, setJobPreviewElement] = useState([]);
    let [job, setJob] = useState(false);
    let [user, setUser] = useState([]);

    let [startDate, setStartDate] = useState(new Date());
    let [endDate, setEndDate] = useState(new Date());
    let [startHour, setStartHour] = useState(new Date());
    let [endHour, setEndHour] = useState(new Date());
    let [originalStartHour, setOriginalStartHour] = useState(new Date());
    let [originalEndHour, setOriginalEndHour] = useState(new Date());
    let [breakDuration, setBreakDuration] = useState(0);
    let [noShow, setNoShow] = useState(0);
    let [reasonOfAbsence, setReasonOfAbsence] = useState("Ziekte");
    let [translations, setTranslations] = useState({});
    let [firstLoad, setFirstLoad] = useState(false);

    // Get id
    if (props.route && props.route.params) {
        if (props.route.params.id) {
            id = props.route.params.id;
        }
    }
    if( props.id ) {
        id = props.id;
    }
    const onFirstLoad = () => {
        const init = async () => {
            /* TRANLATIONS */
            setTranslations(await TranslationAction.translateInLine([
                'Select'
            ]));
        }
    };

    useEffect(() => {
        if (!firstLoad) {
            onFirstLoad();
            setFirstLoad(true);
        }

        /**
         * Confirm hours
         *
         * @param confirm
         * @returns {Promise<void>}
         */
        const confirmHours = async (confirm = true) => {
            // Init
            let url = '/api/employee_shifts/' + job.id;

            // Format hours
            let arrivedAt = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startHour.getHours(), startHour.getMinutes());
            let leftAt = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endHour.getHours(), endHour.getMinutes());
            let startOn = new Date(job.shift.startOn);

            //make sure start on date is always correct
            let formatter = new Intl.DateTimeFormat('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                timeZone: 'europe/brussels'
            });

            let refDate = formatter.format(new Date(startOn));
            let refStartOn = formatter.format(new Date(arrivedAt));
            let refEndOn = formatter.format(new Date(leftAt));

            let arrivedAtOffset = GeneralAction.tzOffsetToTz(arrivedAt.getTimezoneOffset());
            let leftAtOffset = GeneralAction.tzOffsetToTz(leftAt.getTimezoneOffset());

            if (refDate !== refStartOn || refDate !== refEndOn) {
                arrivedAt = new Date(arrivedAt.setDate(startOn.getDate()));
                arrivedAt = new Date(arrivedAt.setMonth(startOn.getMonth()));
                arrivedAt = new Date(arrivedAt.setFullYear(startOn.getFullYear()));
                arrivedAt = GeneralAction.changeTimezone(arrivedAt, arrivedAtOffset);
                leftAt = new Date(leftAt.setDate(startOn.getDate()));
                leftAt = new Date(leftAt.setMonth(startOn.getMonth()));
                leftAt = new Date(leftAt.setFullYear(startOn.getFullYear()));
                leftAt = GeneralAction.changeTimezone(leftAt, leftAtOffset);
            }

            // Modify left at in case shift ended on the next day (e.g. start at 23h -> end at 05h next day
            while (arrivedAt > leftAt) {
                leftAt = new Date(leftAt.getTime() + 24 * 60 * 60 * 1000);
            }


            if (arrivedAt < leftAt) {
                // Save hours through api
                let data = {
                    arrivedAt: noShow ? null : arrivedAt,
                    leftAt: noShow ? null : leftAt,
                    approver: '/api/users/' + user.userId,
                    clientConfirmedAt: new Date(),
                    clientConfirmed: confirm,
                    breakDuration: noShow ? null : breakDuration,
                    reasonOfAbsence: noShow ? reasonOfAbsence : null
                };

                // Update employee shift
                await APIAction.request({ method: 'PATCH', url: url, body: data });

                // Go back (and refresh) to main page app
                if (confirm) {
                    GeneralAction.toast('success', <Trans>Job has been approved</Trans>);
                } else {
                    GeneralAction.toast('success', <Trans>Job has been denied</Trans>);
                }
                props.navigation.replace('Base');
            } else {
                // Error if start date is after end date
                GeneralAction.toast('error', <Trans>End date cannot be earlier than start date</Trans>);
                props.stopApproving();
            }
        };

        if(props.trigger){
            confirmHours();
        }



        const fetchData = async () => {
            // Init
            let element = [];

            // Get user
            let dbUser = await UserAction.getUser();
            setUser(dbUser);

            if (id && !job && !firstLoad) {
                // Get job
                let apiJob = await APIAction.request({ method: 'get', url: '/api/employee_shifts/' + id });

                // Update default dates
                if (apiJob.shift) {
                    if (apiJob.arrivedAt) {
                        setStartHour(new Date(apiJob.arrivedAt));
                        setOriginalStartHour(new Date(apiJob.shift.startOn));
                        setStartDate(new Date(apiJob.arrivedAt));
                    } else {
                        setStartHour(new Date(apiJob.shift.startOn));
                        setOriginalStartHour(new Date(apiJob.shift.startOn));
                        setStartDate(new Date(apiJob.shift.startOn));
                    }

                    if (apiJob.leftAt) {
                        setEndHour(new Date(apiJob.leftAt));
                        setOriginalEndHour(new Date(apiJob.shift.endOn));
                        setEndDate(new Date(apiJob.leftAt));
                    } else {
                        setEndHour(new Date(apiJob.shift.endOn));
                        setOriginalEndHour(new Date(apiJob.shift.endOn));
                        setEndDate(new Date(apiJob.shift.endOn));
                    }

                    if (apiJob.breakDuration) {
                        setBreakDuration(apiJob.breakDuration);
                    }

                    //TODO: set onShow from request
                }

                setJob(apiJob);

                // Get job element
                element.push(<RowItem key={uuidv4()} job={apiJob} headerStyle={true} />);
                setJobPreviewElement(element);
            }
        };

        fetchData();



    }, [firstLoad, job, isFocused, id, props, breakDuration, endDate, endHour, noShow, reasonOfAbsence, startDate, startHour, user]);




    return (
        <>
            <Box style={mainStyle.boxItemVertical}>
            {
                jobPreviewElement.length < 1 && id ?
                    <Box key={uuidv4()} style={[{ marginBottom: 10 }]}>
                        <Placeholder key={uuidv4()} />
                    </Box>
                    :
                    <>
                        {jobPreviewElement}
                        <Heading style={mainStyle.mediumTitle}><Trans>Hours to approve</Trans></Heading>
                        <Stack style={{ width: '100%', paddingTop: 10 }} direction={{ base: 'column', md: 'row' }}>
                            <Box style={{ flex: 1, padding: 10 }}>
                                <HStack style={{ alignItems: 'center' }}>
                                    <Text><Trans>Start</Trans></Text>
                                </HStack>
                                <DateTimePicker disabled={noShow} mode={'time'} value={startHour} onChange={(value) => { setStartHour(value); }} />
                                <Text style={[mainStyle.timeLabel, { position: 'absolute', bottom: 18, right: 15, paddingVertical: 2, paddingHorizontal: 10, display: "flex", justifyContent: "center" }]}>
                                    <Trans>Gepland: </Trans>
                                    {GeneralAction.formatTime(originalStartHour)}
                                </Text>
                            </Box>
                            <Box style={{ flex: 1, padding: 10 }}>
                                <HStack style={{ alignItems: 'center' }}>
                                    <Text><Trans>End</Trans></Text>
                                </HStack>
                                <DateTimePicker disabled={noShow} mode={'time'} value={endHour} onChange={(value) => { setEndHour(value); }} />
                                <Text style={[mainStyle.timeLabel, { position: 'absolute', bottom: 18, right: 15, paddingVertical: 2, paddingHorizontal: 10, display: "flex", justifyContent: "center" }]}>
                                    <Trans>Gepland: </Trans>
                                    {GeneralAction.formatTime(originalEndHour)}
                                </Text>
                            </Box>
                        </Stack>
                        <Stack style={{ width: '100%' }} direction={{ base: 'column', md: 'row' }}>
                            <Box style={{ flexGrow: 1, padding: 10 }}>
                                <HStack style={{ alignItems: 'center' }}>
                                    <Text><Trans>Break duration (in minutes)</Trans></Text>
                                </HStack>
                                <NumberInput
                                    style={noShow ? { backgroundColor: '#F7F7F7', color: '#CCC', cursor: 'not-allowed' } : {}}
                                    _hover={noShow ? { borderColor: '#CECECE' } : {}}
                                    key={'break_duration'}
                                    min={0}
                                    max={300}
                                    value={breakDuration}
                                    hideButtons={true}
                                    isReadOnly={noShow}
                                    onChange={(val) => {
                                        setBreakDuration(val);
                                    }}
                                />
                            </Box>
                            <Box style={{ flexGrow: 0, padding: 10 }}>
                                <HStack>
                                    <Text><Trans>Absent</Trans></Text>
                                </HStack>
                                <Switch
                                    style={{ marginTop: 7 }}
                                    onTrackColor={'#00AAFF'}
                                    defaultIsChecked={noShow}
                                    onValueChange={(val) => {
                                        setNoShow(val);
                                    }}
                                />
                            </Box>
                            <Box style={{ flexGrow: 1, padding: 10 }}>
                                <HStack style={{ alignItems: 'center' }}>
                                    <Text style={{ color: noShow ? 'black' : 'grey' }}><Trans>Reason of absence</Trans></Text>
                                </HStack>
                                <Select
                                    placeholder={translations['Select']}
                                    selectedValue={reasonOfAbsence}
                                    onValueChange={(value) => { setReasonOfAbsence(value) }}
                                    isDisabled={!noShow}
                                >
                                    <Select.Item label={'Ziekte'} value={'Ziekte'} />
                                    <Select.Item label={'Toegestane afwezigheid'} value={'Toegestane afwezigheid'} />
                                    <Select.Item label={'Ongewettigde afwezigheid'} value={'Ongewettigde afwezigheid'} />
                                </Select>
                            </Box>
                        </Stack>
                    </>
            }

            </Box>
            {/*<Stack*/}
            {/*    my={2}*/}
            {/*    direction={{ base: 'row' }}*/}
            {/*    space={2}*/}
            {/*>*/}
            {/*    /!*<Button*/}
            {/*        flex={1}*/}
            {/*        variant={'subtle'}*/}
            {/*        colorScheme={'danger'}*/}
            {/*        onPress={() => confirmHours(false)}*/}
            {/*    >*/}
            {/*        <Text color={'danger.600'}><Trans>Deny</Trans></Text>*/}
            {/*    </Button> *!/*/}
            {/*    <Button*/}
            {/*        flex={1}*/}
            {/*        variant={'subtle'}*/}
            {/*        onPress={() => confirmHours()}*/}
            {/*    >*/}
            {/*        <Text color={'primary.600'}><Trans>Approve</Trans></Text>*/}
            {/*    </Button>*/}
            {/*</Stack>*/}
        </>
    );
};

export default ApproveJob;
