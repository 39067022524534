import React from 'react';
import Trans from '../../Components/Trans';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import { Linking, StyleSheet } from 'react-native';
import main from '../../../Assets/Styles/main.json';
import { Box, Heading, Text, Stack, Skeleton, HStack, Button } from 'native-base';
import GeneralAction from '../../../Actions/GeneralAction';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const Phone = (props) => {

    return (
        <Box style={[props.style]}>
            {props.user ?
                <Box style={[mainStyle.boxItem, { margin: 0, flexDirection: 'column' }]}>
                    <Stack
                        direction={{
                            base: 'column',
                            sm: 'row'
                        }}
                        space={3}
                        justifyContent={{
                            base: 'flex-start',
                            sm: 'space-between'
                        }}
                        alignItems={{
                            base: 'stretch',
                            sm: 'center'
                        }}
                    >
                        <Box>
                            <HStack alignItems={'center'} space={1}>
                                <Box>
                                    <Text style={{ color: 'rgba(0,0,0,0.5)' }}>
                                        <Trans>Mobile</Trans>
                                    </Text>
                                </Box>
                                <Box>
                                    <Icon name={'star'} size={13} style={{ color: '#00aaff' }} />
                                </Box>
                            </HStack>
                            <Heading style={[mainStyle.mediumTitle, { fontSize: 14 }]}>{props.user.phone}</Heading>
                        </Box>
                        <Box flexGrow={1} flexShrink={1} maxWidth={{
                            base: 'full',
                            sm: 250
                        }}>
                            <Button
                                variant={'subtle'}
                                colorScheme={'primary'}
                                borderRadius={'full'}
                                onPress={async () => {
                                    // Call user
                                    let url = 'tel:' + GeneralAction.trim(props.user.phone);
                                    await Linking.openURL(url);
                                }}
                            >
                                <HStack space={1} alignItems={'center'}>
                                    <Icon name={'phone'} size={16} style={{ color: '#00aaff' }} />
                                    <Text style={{ color: '#00aaff', fontSize: 13 }}><Trans>Call now</Trans></Text>
                                </HStack>
                            </Button>
                        </Box>
                    </Stack>
                </Box>
                :
                <Skeleton h={85} />
            }
        </Box>
    );
};

export default Phone;
