import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getToken as getTokenFB, getMessaging, onMessage } from 'firebase/messaging';
import { getPerformance, trace } from 'firebase/performance';
import { getAnalytics, logEvent } from 'firebase/analytics';
import SettingAction from "../../Actions/SettingAction";
import { Modal, ScrollView, Text, Button } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import Trans from '../../Views/Components/Trans'
import firebaseConfig from './firebase-config.json';
import Config from '../Config';
import APIAction from '../../Actions/APIAction';

var app = null;
var messaging = null;
var analytics = null;
var perf = null;

const init = async () => {
    let isSynced = await SettingAction.get('FirebaseLoaded');
    isSynced = !!parseInt(isSynced);

    if (!isSynced) {
        try {
            Notification.requestPermission();
        } catch (e) { }

        app = initializeApp(firebaseConfig);
        messaging = initMessaging();
        analytics = getAnalytics();
        perf = getPerformance(app);
        await getToken();
        await SettingAction.set('FirebaseLoaded', '1');
    }

    return true;
}

const getToken = async () => {
    let notificationIsSupported = !!(window.Notification /* W3C Specification */ || navigator.mozNotification /* Firefox for Android and Firefox OS */);
    if (notificationIsSupported && messaging) {
        try {
            //if service workers are available
            if ('serviceWorker' in navigator) {

                //create getters
                let timer = new Promise(resolve => setTimeout(() => { resolve(null) }, 2000));
                let currentTokenPromise = new Promise(resolve => {
                    getTokenFB(messaging, { vapidKey: 'BCXDkabqS8xUL2MddpmRrdZOny82i-qrqSueq7P3uYsEn3uODApxNHemkSNDDQNOf2m8INJPF0aOuWuHQJJLPxA' })
                        .then((currentToken) => {
                            resolve(currentToken);
                        });
                });

                let currentToken = await Promise.race([timer, currentTokenPromise]);

                if (currentToken) {
                    //save notification token
                    await SettingAction.rem('FirebaseToken');
                    await SettingAction.set('FirebaseToken', currentToken);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
}

const initMessaging = () => {
    let notificationIsSupported = !!(window.Notification /* W3C Specification */ || navigator.mozNotification /* Firefox for Android and Firefox OS */);

    if (notificationIsSupported) {
        try {
            //init messaging
            messaging = getMessaging(app);

        } catch (e) {
            console.log(e);
        }
    }

    return messaging;
}

const initPerformance = (axios) => {
    return null;
}

// Performance traces
const createPerformanceTrace = async (name) => {
    let t = trace(perf, name);
    t.start();
    return t;
}

const stopPerformanceTrace = async (trace) => {
    trace.stop();
}

const MessagingHandler = (props) => {

    const navigation = useNavigation();

    let [notification, setNotification] = useState(null);

    // Subscribe to incoming notifications
    useEffect(() => {
        let unsubscribe = null;
        if (messaging) {
            unsubscribe = onMessage(messaging, (remoteMessage) => {
                setNotification(remoteMessage)
            });
        }

        return unsubscribe;
    }, []);

    useEffect(() => {
        const notificationChannel = new BroadcastChannel('notification');
        const loadedChannel = new BroadcastChannel('loaded');
        
        // Show stuff page as loaded
        loadedChannel.postMessage('loaded');

        let listener = notificationChannel.onmessage = (e) => {
            setNotification(e.data);
        };
        return listener;
    }, []);

    //if notification exists
    if (null !== notification) {
        return <>
            <Modal isOpen={true} onClose={() => setNotification(null)}>
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header>{notification.notification.title}</Modal.Header>
                    <Modal.Body>
                        <ScrollView >
                            <Text>
                                {notification.notification.body}
                            </Text>
                        </ScrollView>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group space={'navigate' in notification.data ? 2 : 1}>
                            <Button variant={'ghost'} onPress={() => {
                                setNotification(null);
                            }}>
                                <Text><Trans>Cancel</Trans></Text>
                            </Button>
                            {'navigate' in notification.data &&
                                <Button onPress={() => {
                                    const navigateTo = JSON.parse(notification.data.navigate);
                                    navigation.navigate(navigateTo.page, navigateTo.params);
                                    setNotification(null);
                                }}>
                                    <Text color={'white'}><Trans>Go</Trans></Text>
                                </Button>
                            }
                            {'impersonation' in notification.data &&
                                <Button onPress={() => {
                                    APIAction.impersonate(notification.data.impersonation);
                                }}>
                                    <Text color={'white'}><Trans>Yes</Trans></Text>
                                </Button>
                            }
                        </Button.Group>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
        </>;
    }

    //if no notification exists
    return <></>;
}

//JSX for link events
const LinkHandler = (props) => {

    useEffect(() => {
        if (typeof props.onTrigger === 'function') {
            props.onTrigger(JSON.parse(JSON.stringify(localStorage.getItem('InitialUrl'))));
            localStorage.removeItem('InitialUrl');
        }
    }, [props]);

    return <></>;
};

//log screen for analytics
const logAnalytics = async ({ screen_name, screen_class }) => {

    let result = null;

    if (null === analytics) {
        try {
            analytics = getAnalytics();
        } catch (e) { }
    }

    if (analytics) {
        result = logEvent(analytics, 'screen_view', {
            app_name: 'Skedle',
            screen_name: screen_name,
            screen_class: screen_class,
            app_version: Config.VERSION_STRING
        })
    }
    return result;
}

//crashlytics id (dummy)
const setCrashlyticsId = (id) => {

};

//crashlutics log (dummy)
const logCrashlytics = (val) => {

}

export { init, getToken, initPerformance, MessagingHandler, LinkHandler, logAnalytics, setCrashlyticsId, logCrashlytics, createPerformanceTrace, stopPerformanceTrace };