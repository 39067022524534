import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Input } from 'native-base';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../Assets/Fontello/line-awesome-config.json';
const Icon = createIconSetFromFontello(lineAwesomeConfig);


const NumberInput = (props) => {

    const [value, setValue] = useState(props.value || '');
    const numberInterval = useRef(null);
    const prevValue = useRef();
    const isDecimal = useRef('isDecimal' in props);

    const update = useCallback((input) => {

        if (input !== prevValue.current) {
            let parsed = Number.parseFloat(input);
            if (Number.isNaN(parsed)) parsed = 0;

            if (typeof props.max === 'number') {
                if (props.max < parsed) {
                    parsed = JSON.parse(JSON.stringify(props.max));
                }
            }

            if (typeof props.min === 'number') {
                if (props.min > parsed) {
                    parsed = JSON.parse(JSON.stringify(props.min));
                }
            }

            if (!isDecimal.current) {
                parsed = Math.round(parsed);
            }

            setValue(JSON.parse(JSON.stringify(parsed)) + '');

            if (typeof props.onChange === 'function') {
                props.onChange(JSON.parse(JSON.stringify(parsed)));
            }
        }
        prevValue.current = input;
    }, [props]);

    useEffect(() => {
        if (typeof props.value === 'number') {
            setValue(props.value + '');
        }
    }, [props.value]);


    let tempValue = value + '';
    let showValue = tempValue.replace('d_', '');

    return (
        <Input
            {...props}
            type={'text'}
            textAlign={'center'}
            keyboardType={'numeric'}
            value={showValue}
            onChangeText={(val) => {
                setValue(val);
                clearInterval(numberInterval.current);
                numberInterval.current = setInterval(() => {
                    update(val);
                    clearInterval(numberInterval.current);
                }, 1000);
            }}
            InputLeftElement={
                !props.hideButtons ?
                    <Button size={'xs'} rounded={'none'} h={'full'} variant={'subtle'} onPress={() => {
                        setTimeout(() => {
                            let number = Number.parseFloat(value);
                            if (Number.isNaN(number)) number = 0;
                            number--;
                            if (typeof props.min === 'number' && props.min > number) number = props.min;
                            update(number);
                        }, 0);

                    }}>
                        <Icon color={'#00AAFF'} name={'minus'} />
                    </Button>
                :
                    null
            }
            InputRightElement={
                !props.hideButtons ?
                    <Button size={'xs'} rounded={'none'} h={'full'} variant={'subtle'} onPress={() => {
                        setTimeout(() => {
                            let number = Number.parseFloat(value);
                            if (Number.isNaN(number)) number = 0;
                            number++;
                            if (typeof props.max === 'number' && props.max < number) number = props.max;
                            update(number);
                        }, 0);

                    }}>
                        <Icon color={'#00AAFF'} name={'plus'} />
                    </Button>
                :
                    null
            }
        />
    );
};

export { NumberInput };