import React, { useCallback, useEffect, useRef, useState } from 'react';
import { VStack, Box, Text, Input, FormControl, HStack, Button, Select, Divider, useTheme, Stack, CheckIcon, TextArea } from 'native-base';
import Drawer from '../../../Libs/Drawer';
import DateTimePicker from '../../../Libs/DateTimePicker';
import Trans from '../../Components/Trans';
import GeneralAction from '../../../Actions/GeneralAction';
import APIAction from '../../../Actions/APIAction';
import RequestCacheAction from '../../../Actions/RequestCacheAction';
import TranslationAction from '../../../Actions/TranslationAction';
import ProjectShiftEditBox from './ProjectShiftEditBox';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import { v4 as uuidv4 } from 'uuid';
import UserAction from '../../../Actions/UserAction';
import { useRecoilState } from 'recoil';
import ShiftEditPaneAppendState from '../../../Recoil/ShiftEditPaneAppendState';
import { Platform } from 'react-native';

const Icon = createIconSetFromFontello(lineAwesomeConfig);

const ProjectEditPane = (props) => {
    const
        [shiftPaneAppend, setShiftPaneAppend] = useRecoilState(ShiftEditPaneAppendState),
        firstLoad = useRef(true),
        [open, setOpen] = useState(props.open || false),
        [formData, setFormData] = useState(props.project ? {
            name: props.project.name,
            startDate: props.project.startDate,
            endDate: props.project.endDate,
            projectInfo: props.project.projectInfo || '',
            address: props.project.address?.['@id']
        } : {
            projectInfo: '',
            startDate: props.date ? new Date(new Date(props.date).setHours(0, 0, 0, 0)) : new Date().setHours(0, 0, 0, 0),
            endDate: props.date ? new Date(new Date(props.date).setDate(new Date(props.date).getDate() + 1)).setHours(23, 59, 59, 999) : new Date().setDate(new Date(new Date().setHours(23, 59, 59, 999)).getDate() + 1)
        }),
        [translations, setTranslations] = useState(null),
        [addressItems, setAddressItems] = useState(null),
        [project, setProject] = useState(false),
        [showCreateShift, setShowCreateShift] = useState(false),
        [newAddress, setNewAddress] = useState({
            street: null,
            streetNumber: null,
            zip: null,
            place: null,
            country: null
        }),
        [newAddressShow, setNewAddressShow] = useState(false),
        [countries, setCountries] = useState(null),
        [countryOptions, setCountryOptions] = useState(null),
        { colors } = useTheme(),
        projectInfoTimeout = useRef(null)
        ;

    const onMount = useCallback(() => {
        const init = async () => {

            //language
            let language = await TranslationAction.getSelectedLanguage();

            //translations
            let newTranslations = await TranslationAction.translateInLine(['Select']);
            setTranslations(newTranslations);

            //Addresses
            setAddresses();

            // Get countries
            let countryList = await GeneralAction.getCountries();
            setCountries(countryList);

            let options = [];
            for (const key in countryList) {
                let value = countryList[key];
                options.push(<Select.Item key={uuidv4()} label={value} value={key} />);
            }

            setCountryOptions(options);

        };
        init();
    }, []);

    useEffect(() => {
        if (firstLoad.current && open) {
            firstLoad.current = false;
            onMount();
        }
    }, [onMount, open]);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    useEffect(() => {
        if (props.date) {
            setFormData({
                name: formData.name,
                address: formData.address,
                projectInfo: formData.projectInfo,
                startDate: formData.startDate ? new Date(formData.startDate).toISOString() : new Date(new Date(Date.now() + 86400000).setHours(0, 0, 0, 0)).toISOString(),
                endDate: formData.endDate ? new Date(formData.endDate).toISOString() : new Date(new Date(Date.now() + 86400000).setHours(23, 59, 59, 999)).toISOString()
            });
        }
    }, [props.date, formData.name, formData.address, formData.projectInfo, formData.startDate, formData.endDate]);

    const close = () => {
        if (typeof props.onClose === 'function') {
            props.onClose();
        }
        if (!props.isNewProject) {
            setOpen(false);
            setFormData({
                name: props.project.name,
                startDate: props.project.startDate,
                endDate: props.project.endDate,
                projectInfo: props.project.projectInfo || '',
                address: props.project.address?.['@id']
            });
        } else {
            setFormData(
                {
                    name: null,
                    projectInfo: '',
                    startDate: new Date(new Date(Date.now() + 86400000).setHours(0, 0, 0, 0)).toISOString(),
                    endDate: new Date(new Date(Date.now() + 86400000).setHours(23, 59, 59, 999)).toISOString()
                });
        }
        setProject(null);
    };

    const setAddresses = async () => {
        //addresses
        let addresses = await APIAction.request({
            method: 'GET',
            url: '/api/addresses',
            cache: false
        });
        addresses = addresses['hydra:member'];

        let newAddressItems = [];
        for (let address of addresses) {
            let country = await GeneralAction.getCountry(address.country);
            let label = `${address.street} ${address.streetNumber}, ${address.zip} ${address.place}, ${country}`;
            newAddressItems.push(
                <Select.Item label={label} value={address['@id']} />
            );
        }
        setAddressItems(newAddressItems);
    }

    const submitForm = async () => {
        let valid = true;
        if (!formData.name) {
            valid = false;
            GeneralAction.toast('error', <Trans>Fill in name</Trans>);
            await GeneralAction.sleep(20);
        }
        if (
            (!formData.startDate) ||
            (!formData.endDate) ||
            (formData.startDate > formData.endDate)
        ) {
            valid = false;
            GeneralAction.toast('error', <Trans>Dates are not valid</Trans>);
            await GeneralAction.sleep(20);
        }
        if (!newAddressShow && !formData.address) {
            console.log('existing address not valid');
            valid = false;
            GeneralAction.toast('error', <Trans>Fill in address</Trans>);
            await GeneralAction.sleep(20);
        }
        if (newAddressShow && !(newAddress.street && newAddress.streetNumber && newAddress.place && newAddress.zip && newAddress.country)) {
            console.log('new address not valid');
            valid = false;
            GeneralAction.toast('error', <Trans>Fill in address</Trans>);
            await GeneralAction.sleep(20);
        }

        if (valid) {
            console.log('valid');

            let currentUser = await UserAction.getUser();

            if (newAddressShow) {
                let address = await APIAction.request({
                    method: 'POST',
                    url: '/api/addresses',
                    body: {
                        street: newAddress.street,
                        streetNumber: newAddress.streetNumber,
                        zip: newAddress.zip,
                        place: newAddress.place,
                        country: newAddress.country,
                        client: currentUser.client
                    }
                });
                if (address?.status && address?.status === 'NOK') {
                    GeneralAction.toast('error', response?.message);
                    await GeneralAction.sleep(20);
                } else {
                    formData.address = address['@id'];
                    setAddresses();
                }
            }

            let response = null;
            if (props.project) {
                response = await APIAction.request({
                    method: 'PATCH',
                    url: `/api/projects/${props.project.id}`,
                    body: formData
                });
            } else {
                response = await APIAction.request({
                    method: 'POST',
                    url: '/api/projects',
                    body: formData
                });
            }

            if (response?.status && response?.status === 'NOK') {
                GeneralAction.toast('error', response?.message);
                await GeneralAction.sleep(20);
            } else {
                await RequestCacheAction.clear({ url: 'projects' });
                if (props.isNewProject) {
                    setProject(response);
                }
                else {
                    close();
                }

                if (typeof props.onReload === 'function') {
                    props.onReload();
                }
            }
        }
    };

    return (
        <>
            <Drawer open={open} onClose={() => close()} style={{ display: (shiftPaneAppend === null ? 'flex' : 'none') }}>
                {open ?
                    <>
                        {!project ?
                            <VStack p={3} space={2}>
                                {props.isNewProject ?
                                    <HStack
                                        space={5}
                                        alignItems={'center'}
                                    >
                                        <Box>
                                            <Text><Trans>Step 1</Trans></Text>
                                            <Text color={'secondary.600'} fontSize={14} fontWeight={'bold'} textTransform={'uppercase'}>{props.title}</Text>
                                        </Box>
                                        <Box backgroundColor={'secondary.600'} h={0.5} flexGrow={1} flexShrink={1}>
                                        </Box>
                                        <Box>
                                            <Text color={'lightgrey'}><Trans>Step 2</Trans></Text>
                                            <Text color={'secondary.200'} fontSize={14} fontWeight={'bold'} textTransform={'uppercase'}><Trans>Create shift</Trans></Text>
                                        </Box>
                                    </HStack>
                                    :
                                    <Box>
                                        <Text color={'secondary.600'} fontSize={14} fontWeight={'bold'} textTransform={'uppercase'}>{props.title}</Text>
                                    </Box>
                                }
                                <FormControl>
                                    <FormControl.Label><Text><Trans>Name</Trans></Text></FormControl.Label>
                                    <Input
                                        type={'text'}
                                        value={formData.name}
                                        onChangeText={(value) => {
                                            setFormData({ ...formData, ...{ name: value } });
                                        }}
                                    />
                                </FormControl>
                                <HStack
                                    space={2}
                                >
                                    <Box flex={1}>
                                        <FormControl>
                                            <FormControl.Label><Text><Trans>Date</Trans></Text></FormControl.Label>
                                            <DateTimePicker
                                                mode={'date'}
                                                value={formData?.startDate ? new Date(formData.startDate) : new Date()}//{formData?.startDate ? new Date(formData.startDate) : new Date(Date.now() + 86400000)}
                                                onChange={(value) => {
                                                    try {
                                                        let year = value.getFullYear() + '';
                                                        let month = ((value.getMonth() + 1) + '').padStart(2, '0');
                                                        let day = (value.getDate() + '').padStart(2, '0');
                                                        let stringDate = `${year}-${month}-${day}T00:00:00+01:00`;
                                                        let newDate = new Date(stringDate);
                                                        if (newDate instanceof Date && !isNaN(newDate.valueOf())) {
                                                            setFormData({ ...formData, ...{ startDate: newDate, endDate: newDate } });
                                                        }
                                                    } catch (e) {

                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                </HStack>
                                <HStack
                                    space={2}
                                >
                                    <Box flex={1}>
                                        <FormControl>
                                            <FormControl.Label><Text><Trans>Project Info</Trans></Text></FormControl.Label>
                                            <TextArea
                                                defaultValue={formData.projectInfo}
                                                onChangeText={(value) => {
                                                    clearTimeout(projectInfoTimeout.current);
                                                    projectInfoTimeout.current = setTimeout(() => {
                                                        setFormData({ ...formData, ...{ projectInfo: value } });
                                                    }, 500);

                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                </HStack>
                                {!newAddressShow &&
                                    <>
                                        <FormControl.Label><Text><Trans>Address</Trans></Text></FormControl.Label>
                                        <Stack
                                            direction={{
                                                md: 'row',
                                                base: 'column'
                                            }}
                                            space={{
                                                md: 2,
                                                base: 4
                                            }}
                                        >
                                            <Box flexGrow={1} flexShrink={1} flexBasis={{ md: 1 }}>
                                                <Box>
                                                    <Select
                                                        style={{ flexGrow: 1 }}
                                                        placeholder={'select'}
                                                        selectedValue={formData.address}
                                                        onValueChange={(value) => {
                                                            setFormData({ ...formData, ...{ address: value } })
                                                        }}
                                                    >
                                                        {addressItems}
                                                    </Select>
                                                </Box>
                                            </Box>
                                            <Box flexGrow={1} flexShrink={1} flexBasis={{ md: 1 }}>
                                                <Box>
                                                    <Button
                                                        leftIcon={<Icon size={24} style={{ color: colors['secondary']['600'] }} name={'plus'} />}
                                                        colorScheme={'secondary'}
                                                        onPress={() => { setNewAddressShow(true) }}
                                                        variant={'outline'}
                                                        borderRadius={'md'}
                                                        padding={1}
                                                    >
                                                        <Text color={"secondary.600"}><Trans>New address</Trans></Text>
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Stack>
                                    </>}
                                {newAddressShow &&
                                    <>
                                        <Stack
                                            direction={{
                                                md: 'row',
                                                base: 'column'
                                            }}
                                            space={{
                                                md: 2,
                                                base: 4
                                            }}
                                        >
                                            <Box flexGrow={1} flexShrink={1} flexBasis={{ md: 1 }}>
                                                <Box>
                                                    <FormControl>
                                                        <FormControl.Label><Text><Trans>Street</Trans></Text></FormControl.Label>
                                                        <Input
                                                            type={'text'}
                                                            value={newAddress.street}
                                                            onChangeText={(value) => {
                                                                setNewAddress({ ...newAddress, ...{ street: value } });
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                            <Box flexGrow={1} flexShrink={1} flexBasis={{ md: 1 }}>
                                                <Box>
                                                    <FormControl>
                                                        <FormControl.Label><Text><Trans>Street nr</Trans></Text></FormControl.Label>
                                                        <Input
                                                            type={'text'}
                                                            value={newAddress.streetNumber}
                                                            onChangeText={(value) => {
                                                                setNewAddress({ ...newAddress, ...{ streetNumber: value } });
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </Stack>
                                        <Stack
                                            direction={{
                                                md: 'row',
                                                base: 'column'
                                            }}
                                            space={{
                                                md: 2,
                                                base: 4
                                            }}
                                        >
                                            <Box flexGrow={1} flexShrink={1} flexBasis={{ md: 1 }}>
                                                <Box>
                                                    <FormControl>
                                                        <FormControl.Label><Text><Trans>Zip</Trans></Text></FormControl.Label>
                                                        <Input
                                                            type={'text'}
                                                            value={newAddress.zip}
                                                            onChangeText={(value) => {
                                                                setNewAddress({ ...newAddress, ...{ zip: value } });
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                            <Box flexGrow={1} flexShrink={1} flexBasis={{ md: 1 }}>
                                                <Box>
                                                    <FormControl>
                                                        <FormControl.Label><Text><Trans>Place</Trans></Text></FormControl.Label>
                                                        <Input
                                                            type={'text'}
                                                            value={newAddress.place}
                                                            onChangeText={(value) => {
                                                                setNewAddress({ ...newAddress, ...{ place: value } });
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </Stack>
                                        <FormControl.Label><Text><Trans>Country</Trans></Text></FormControl.Label>
                                        <Stack
                                            direction={{
                                                md: 'row',
                                                base: 'column'
                                            }}
                                            space={{
                                                md: 2,
                                                base: 4
                                            }}
                                        >
                                            <Box flexGrow={1} flexShrink={1} flexBasis={{ md: 1 }}>
                                                <Box>
                                                    <FormControl>
                                                        <Select
                                                            selectedValue={newAddress.country}
                                                            w="100%"
                                                            _selectedItem={{ bg: '#00aaff', color: '#ffffff', endIcon: <CheckIcon size={5} /> }}
                                                            onValueChange={value => setNewAddress({ ...newAddress, ...{ country: value } })}
                                                        >
                                                            {countryOptions}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                            <Box flexGrow={1} flexShrink={1} flexBasis={{ md: 1 }}>
                                                <Box>
                                                    <Button
                                                        leftIcon={<Icon size={24} style={{ color: colors['secondary']['600'] }} name={'plus'} />}
                                                        colorScheme={'secondary'}
                                                        onPress={() => { setNewAddressShow(false) }}
                                                        variant={'outline'}
                                                        borderRadius={'md'}
                                                        padding={1}
                                                    >
                                                        <Text color={"secondary.600"}><Trans>Use existing address</Trans></Text>
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Stack>
                                    </>}
                                <HStack space={2}>
                                    <Button
                                        flex={1}
                                        variant={'subtle'}
                                        colorScheme={'secondary'}
                                        onPress={() => close()}
                                    >
                                        <Text color={'secondary.600'}><Trans>Close</Trans></Text>
                                    </Button>
                                    <Button
                                        flex={1}
                                        variant={'subtle'}
                                        colorScheme={'primary'}
                                        onPress={() => {
                                            submitForm();
                                        }}
                                    >
                                        <Text color={'primary.600'}><Trans>{props.isNewProject ? 'Next' : 'Save'}</Trans></Text>
                                    </Button>
                                </HStack>
                            </VStack>
                            :
                            <VStack>
                                <Box>
                                    <ProjectShiftEditBox
                                        project={project}
                                        open={true}
                                        onClose={() => {
                                            const today = new Date();
                                            setFormData({
                                                startDate: new Date(today.getTime() + 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0),
                                                endDate: new Date(today.getTime() + 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0),
                                                name: undefined,
                                                address: undefined
                                            });
                                            setProject(false);
                                            setNewAddress({
                                                street: null,
                                                streetNumber: null,
                                                zip: null,
                                                place: null,
                                                country: null
                                            });
                                            setOpen(false);
                                            props.onClose();
                                        }}
                                        inProjectFlow={true}
                                        reload={() => {
                                            props.onReload();
                                        }}
                                    />
                                </Box>
                            </VStack>}
                    </>:
                    null
                }
            </Drawer>
        </>
    );
};

export default ProjectEditPane;