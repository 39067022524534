import React, { useState, useEffect } from 'react';
import Trans from '../../Components/Trans';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import lineAwesomeConfig from '../../../Assets/Fontello/line-awesome-config.json';
import { StyleSheet } from 'react-native';
import main from '../../../Assets/Styles/main.json';
import { Box, Heading, HStack, Skeleton, Stack, Text, VStack } from 'native-base';
import UserImage from '../../Components/UserImage';
import TranslationAction from '../../../Actions/TranslationAction';

const Icon = createIconSetFromFontello(lineAwesomeConfig);
const mainStyle = StyleSheet.create(main);

const Name = (props) => {

    return (
        <Box style={[props.style]}>
            {
                props.user ?
                    <Box>
                        <Stack
                            space={{
                                base: 2,
                                md: 5
                            }}
                            direction={{
                                base: 'column',
                                md: 'row'
                            }}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <UserImage
                                style={{ width: 100, height: 100 }}
                                textStyle={{ fontSize: 40, lineHeight: 100 }}
                                user={props.user}
                            />

                            <VStack>
                                <Heading>{props.user.firstName + ' ' + props.user.lastName}</Heading>
                                <HStack
                                    space={1}
                                    justifyContent={{
                                        base: 'center',
                                        md: 'flex-start'
                                    }}
                                    alignItems={"center"}
                                >
                                    <Icon name={'briefcase'} size={18} style={{ color: '#00aaff' }} />
                                    <Text>{props.user.employee.contractType.name[props.locale]}</Text>
                                </HStack>
                            </VStack>
                        </Stack>
                    </Box>
                    :
                    <Box>
                        <Stack
                            space={{
                                base: 2,
                                md: 5
                            }}
                            direction={{
                                base: 'column',
                                md: 'row'
                            }}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Skeleton h={100} w={100} borderRadius={'full'} />
                            <Skeleton.Text lines={2} width={250} />
                        </Stack>
                    </Box>
            }
        </Box>
    );
};

export default Name;
